import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import {
  ActionButton,
  CurrencyFormatters,
  DesktopPopupModal,
  GenericModalContent,
  Icon,
  IconName,
  ImportantInfoList,
  MobilePopoverCard,
  removeTimezone,
} from "halifax";
import { last } from "lodash";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  CfarEvents,
  ExchangeActionEnum,
  ExchangeScenario,
  FlightItinerarySlice,
  IAgentFulfillFlightExchangeReq,
  IExchangeSubmitSegment,
  IExchangeSubmitSlice,
  IExchangeType,
  SelfServeEvents,
  Slice,
  TravelItineraryEnum,
} from "redmond";

import { trackEvent } from "../../api/v1/analytics/trackEvent";
import submitFlightExchange from "../../api/v1/exchange/submitFlightExchangeV2";
import { buttonText, confirmCopy, SliceType, TripType } from "../../constants";
import {
  getAirportMap,
  getConfirmPageCopy,
  getExchangeScenario,
  getExchangeType,
  getExchangeTypeEvent,
  getFlightBooking,
  getFlights,
  getOriginalExchangeFee,
  getPolicyHasCfar,
  getSelectedPnrHfv2,
  getShoppedDepartureDate,
  getShoppedReturnDate,
  getShoppedTrip,
  getTravelItinerary,
  getTripDetails,
} from "../../selectors";
import { ExchangeModuleRootState } from "../../store";
import {
  getReviewCardHeaderWithType,
  getSliceIndex,
} from "../../utils/helpers";
import { PATH_FLIGHT_EXCHANGE, TRIPS_HOME } from "../../utils/paths";
import { MobileItineraryDetailsModal } from "../FlightReshop/components/MobileItineraryDetailsModal";
import { MobileAirlineDetailsCard } from "../MobileAirlineDetailsCard";
import { CheckoutBreakdown } from "./components/CheckoutBreakdown";
import { FlightSummaryCard } from "./components/FlightSummaryCard";

import "./styles.scss";
import { getItinerarySlices } from "../../utils/flightSlices";

export interface IConfirmFlightExchangeProps extends RouteComponentProps {
  isMobile: boolean;
}

const defaultProps: Partial<IConfirmFlightExchangeProps> = {
  isMobile: false,
};

const ConfirmFlightExchange = (
  props: IConfirmFlightExchangeProps
): JSX.Element => {
  const { history, isMobile } = props;

  const modalActionsRef = useRef<ReactNode>(null);
  const modalIconRef = useRef<ReactNode>(null);
  const modalSubtitleRef = useRef("");
  const modalTitleRef = useRef("");
  const totalPriceRef = useRef("");

  const airports = useSelector(getAirportMap);
  const booking = useSelector(getFlightBooking);
  const confirmPageCopy = useSelector(getConfirmPageCopy);
  const departureDate = useSelector(getShoppedDepartureDate);
  const exchangeType = useSelector(getExchangeType);
  const exchangeTypeEvent = useSelector(getExchangeTypeEvent);
  const flights = useSelector(getFlights);
  const hasCfar = useSelector(getPolicyHasCfar);
  const ogChangeFee = useSelector(getOriginalExchangeFee);
  const returnDate = useSelector(getShoppedReturnDate);
  const scenario = useSelector(getExchangeScenario);
  const selectedPnrHfv2 = useSelector(getSelectedPnrHfv2);
  const travelItinerary = useSelector(getTravelItinerary)?.TravelItinerary;
  const shoppedTrip = useSelector(getShoppedTrip);
  const tripDetails = useSelector((state: ExchangeModuleRootState) =>
    getTripDetails(state, shoppedTrip.tripId!)
  );
  const isMultiTravelItinerary =
    travelItinerary === TravelItineraryEnum.MultiTravelItinerary;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobileSummaryOpen, setMobileSummaryOpen] = useState<SliceType>();
  const [modalOpen, setModalOpen] = useState(false);

  const { outboundSelection, returnSelection } = exchangeType;
  const departureRemoved =
    outboundSelection.ExchangeAction === ExchangeActionEnum.remove;
  const returnRemoved =
    returnSelection?.ExchangeAction === ExchangeActionEnum.remove;
  const selectedFareId = shoppedTrip.returnFareId || shoppedTrip.outgoingFareId;
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === selectedFareId
  );

  const { outgoingSlice, returnSlice } = useMemo(() => {
    return getItinerarySlices(
      shoppedTrip,
      outboundSelection,
      returnSelection,
      booking,
      flights!.slices
    );
  }, [flights, outboundSelection, returnSelection, shoppedTrip]);

  const filterSlicesForPayload = ({
    slices,
    exchangeType,
  }: {
    slices: IExchangeSubmitSlice[];
    exchangeType: IExchangeType;
  }) => {
    if (isMultiTravelItinerary) {
      // if multitravel send only the affected slice to BE
      if (
        exchangeType.outboundSelection.ExchangeAction ===
        ExchangeActionEnum.change
      ) {
        return slices.slice(0, 1);
      }

      if (
        exchangeType.returnSelection?.ExchangeAction ===
        ExchangeActionEnum.change
      ) {
        return slices.slice(-1);
      }
    }

    return slices;
  };

  const formatPayloadSlice = (
    slice: FlightItinerarySlice | Slice | null,
    isOutbound = false
  ) => {
    const arrSlice: IExchangeSubmitSlice[] = [];

    if (slice && "id" in slice) {
      // handles new slice from shop response
      const fareDetailsSlice = isOutbound
        ? fareDetails?.slices[0]
        : last(fareDetails?.slices);
      const bookingCodes =
        fareDetailsSlice?.fareDetails.segments.map(
          (fSeg) => fSeg.bookingCode ?? ""
        ) ?? [];
      const formattedSegments = slice.segments.map<IExchangeSubmitSegment>(
        (seg, idx) => ({
          arrivalTime: seg.arrival,
          departureTime: seg.departure,
          destination: seg.destination,
          fareClassCode: bookingCodes[idx] ?? "",
          flightNumber: seg.flightNumber,
          marketingCarrier: seg.marketingAirline,
          origin: seg.origin,
        })
      );

      arrSlice.push({
        segments: formattedSegments,
      });
    } else if (slice) {
      // handles retained slice found in booking
      const exchangeReqSegments = slice.segments.map<IExchangeSubmitSegment>(
        (seg) => ({
          arrivalTime: seg.updatedArrival,
          departureTime: seg.updatedDeparture,
          destination: seg.destination.locationCode,
          fareClassCode: seg.bookingClassCode,
          flightNumber: String(seg.marketingAirline.flightNumber),
          marketingCarrier: seg.marketingAirline.code,
          origin: seg.origin.locationCode,
        })
      );

      arrSlice.push({
        segments: exchangeReqSegments,
      });
    }

    return arrSlice;
  };

  const onModalClose = (ev: MouseEvent, reason: string) => {
    if (reason !== "backdropClick") {
      ev?.stopPropagation?.();
      setModalOpen(false);

      history.push({
        pathname: TRIPS_HOME,
        search: `?tripId=${booking?.bookedItinerary.id ?? ""}`,
      });
    }
  };

  const renderMobileAirlineCard = (
    isDeparture: boolean,
    date: dayjs.Dayjs,
    isMixedClass: boolean
  ) => {
    const sliceIdx = getSliceIndex(isDeparture, tripDetails);
    const {
      arrivalTime,
      departureTime,
      destinationCode,
      destinationName,
      segmentDetails,
      stops,
      totalDurationMinutes,
    } = tripDetails.slices[sliceIdx];
    const location = airports[destinationCode]?.cityName ?? destinationName;
    const sliceType = isDeparture ? SliceType.departure : SliceType.return;
    const { description, type } = getReviewCardHeaderWithType(
      isDeparture,
      location,
      date
    );

    return (
      <MobileAirlineDetailsCard
        arrivalTime={removeTimezone(arrivalTime)}
        departureTime={removeTimezone(departureTime)}
        description={description}
        duration={totalDurationMinutes ?? 0}
        firstTripSegment={segmentDetails[0]}
        isDeparture={isDeparture}
        isMixedClass={isMixedClass}
        onClick={() => setMobileSummaryOpen(sliceType)}
        stops={stops}
        type={type}
      />
    );
  };

  const submitExchangeRequest = () => {
    setIsSubmitting(true);
    const { id, travelItinerary } = booking!.bookedItinerary;
    const { unscopedValue } = travelItinerary.locators?.agent ?? {};
    const pnr =
      (isMultiTravelItinerary && Boolean(selectedPnrHfv2)
        ? selectedPnrHfv2
        : unscopedValue) || "";
    const slices = [
      ...formatPayloadSlice(outgoingSlice, true),
      ...formatPayloadSlice(returnSlice),
    ];

    const requestPayload: IAgentFulfillFlightExchangeReq = {
      pnr,
      itinerary: {
        slices: filterSlicesForPayload({ slices, exchangeType }),
      },
      reservationId: id,
      totalPrice: totalPriceRef.current,
    };

    let agentLocator = booking?.bookedItinerary.travelItinerary.locators?.agent;
    let completedRequestSelfServeProperties = {
      success: false,
      rebook_eligible: false,
      reshop_eligible: true,
      agent_locator_provider: agentLocator?.provider,
      agent_locator: agentLocator?.value,
      request_type:
        scenario === ExchangeScenario.ftc ? "ftc_exchange" : "exchange",
    };
    return submitFlightExchange(requestPayload)
      .then(() => {
        trackEvent({
          eventName:
            scenario === ExchangeScenario.ftc
              ? SelfServeEvents.FTCExchangeSubmitSuccess
              : SelfServeEvents.ExchangeSubmitSuccess,
          properties: {
            url: window.location.pathname,
            exchange_fee: ogChangeFee.amount,
            exchange_type: exchangeTypeEvent,
          },
        });

        completedRequestSelfServeProperties.success = true;
        trackEvent({
          eventName: SelfServeEvents.CompletedRequestSelfServe,
          properties: completedRequestSelfServeProperties,
        });

        if (hasCfar && ogChangeFee) {
          const { amount, currency } = ogChangeFee;

          trackEvent({
            eventName: CfarEvents.RebookingFeeWaived,
            properties: {
              agent_locator:
                booking?.bookedItinerary.travelItinerary.locators?.agent,
              originalChangeFee:
                CurrencyFormatters.get(currency).format(amount),
            },
          });
        }

        if (confirmPageCopy) {
          const { body, title } = confirmPageCopy.informativeSection;
          const subtitle = body.length
            ? body.join(". ")
            : confirmCopy.SUBMIT_SUCCESS_SUBTITLE;

          modalSubtitleRef.current = subtitle;
          modalTitleRef.current = title || confirmCopy.SUBMIT_SUCCESS;
        }

        if (!modalSubtitleRef.current) {
          modalSubtitleRef.current = confirmCopy.SUBMIT_SUCCESS_SUBTITLE;
        }

        if (!modalTitleRef.current) {
          modalTitleRef.current = confirmCopy.SUBMIT_SUCCESS;
        }

        modalActionsRef.current = (
          <ActionButton
            className="done-btn"
            message={buttonText.DONE}
            onClick={onModalClose as any}
          />
        );
        modalIconRef.current = (
          <Icon className="success-icon" name={IconName.Checked} />
        );
      })
      .catch(() => {
        trackEvent({
          eventName:
            scenario === ExchangeScenario.ftc
              ? SelfServeEvents.FTCExchangeSubmitFailure
              : SelfServeEvents.ExchangeSubmitFailure,
          properties: {
            url: window.location.pathname,
            exchange_type: exchangeTypeEvent,
            exchange_fee: ogChangeFee.amount,
          },
        });
        trackEvent({
          eventName: SelfServeEvents.CompletedRequestSelfServe,
          properties: completedRequestSelfServeProperties,
        });

        modalIconRef.current = (
          <Icon className="failure-icon" name={IconName.ErrorState} />
        );
        modalTitleRef.current = confirmCopy.ISSUE_SUBMITTING_REQ;
        modalSubtitleRef.current = confirmCopy.SUBMIT_TRY_AGAIN;
        modalActionsRef.current = (
          <ActionButton
            className="contact-support-btn"
            message={buttonText.TRY_AGAIN}
            onClick={() => {
              setModalOpen(false);
              submitExchangeRequest();
            }}
          />
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        setModalOpen(true);
      });
  };

  const ExchangeHeader = useMemo(
    () => (
      <Box className="copy-header">
        <Typography className="review-title">
          {confirmPageCopy?.title ?? confirmCopy.REVIEW_TITLE}
        </Typography>
        {confirmPageCopy?.body.map((subtitle: string) => (
          <Typography
            className="review-subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
            key={subtitle}
          />
        ))}
      </Box>
    ),
    [confirmPageCopy]
  );

  // reroute back to landing page if booking isn't set
  useEffect(() => {
    if (!booking) {
      history.push({
        pathname: PATH_FLIGHT_EXCHANGE,
        search: history.location.search,
      });
    }
  }, [booking, history]);

  useEffect(() => {
    trackEvent({
      eventName:
        scenario === ExchangeScenario.ftc
          ? SelfServeEvents.ViewedFTCExchangeConfirmPage
          : SelfServeEvents.ViewedExchangeConfirmPage,
      properties: {
        url: window.location.pathname,
        exchange_type: exchangeTypeEvent,
        exchange_fee: ogChangeFee.amount,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobile ? (
    <Box className="mobile-confirm-flight-exchange">
      {ExchangeHeader}
      <Box className="itinerary-cards-section">
        {!departureRemoved && departureDate && fareDetails && (
          <Box className="mobile-trip-card">
            {renderMobileAirlineCard(true, departureDate, false)}
          </Box>
        )}
        {!returnRemoved && returnDate && fareDetails && (
          <Box className="mobile-trip-card">
            {renderMobileAirlineCard(false, returnDate, false)}
          </Box>
        )}
      </Box>
      {confirmPageCopy?.importantInfo?.length ? (
        <ImportantInfoList
          ordered
          infoItems={confirmPageCopy.importantInfo}
          title={confirmCopy.IMPORTANT_INFO}
        />
      ) : null}
      <CheckoutBreakdown
        isMobile
        isSubmitting={isSubmitting}
        onSubmit={submitExchangeRequest}
        totalPriceRef={totalPriceRef}
        submitButtonText={buttonText.SUBMIT_REQUEST}
        enableSubmitButton={true}
        priceQuote={undefined}
      />
      <MobileItineraryDetailsModal
        fareDetails={fareDetails}
        isDeparture={mobileSummaryOpen === SliceType.departure}
        isMixedCabinClass={false}
        onClose={() => setMobileSummaryOpen(undefined)}
        open={Boolean(mobileSummaryOpen)}
        tripDetails={tripDetails}
      />
      {modalOpen && (
        <MobilePopoverCard
          centered
          disableEscapeKeyDown
          className="submit-request-response-modal mobile"
          contentClassName="modal-content"
          onClose={onModalClose}
          open={modalOpen}
        >
          <GenericModalContent
            actions={modalActionsRef.current}
            image={modalIconRef.current}
            subtitle={modalSubtitleRef.current}
            title={modalTitleRef.current}
          />
        </MobilePopoverCard>
      )}
    </Box>
  ) : (
    <Box className="confirm-flight-exchange-root">
      <Box className="exchange-summary">
        {ExchangeHeader}
        <Box className="shopped-trip-overview">
          {outgoingSlice && (
            <FlightSummaryCard
              className="outbound"
              isMobile={isMobile}
              key="outbound-slice"
              slice={outgoingSlice}
              tripType={TripType.Outbound}
            />
          )}
          {returnSlice && (
            <FlightSummaryCard
              className="return"
              isMobile={isMobile}
              key="return-slice"
              slice={returnSlice}
              tripType={TripType.Return}
            />
          )}
        </Box>
        <CheckoutBreakdown
          isSubmitting={isSubmitting}
          onSubmit={submitExchangeRequest}
          totalPriceRef={totalPriceRef}
          submitButtonText={buttonText.SUBMIT_REQUEST}
          enableSubmitButton={true}
          priceQuote={undefined}
        />
        {confirmPageCopy?.importantInfo?.length ? (
          <ImportantInfoList
            ordered
            infoItems={confirmPageCopy.importantInfo}
            title={confirmCopy.IMPORTANT_INFO}
          />
        ) : null}
      </Box>
      {modalOpen && (
        <DesktopPopupModal
          disableEscapeKeyDown
          invisibleBackdrop={false}
          className="submit-request-response-modal"
          onClose={onModalClose}
          open={modalOpen}
        >
          <GenericModalContent
            actions={modalActionsRef.current}
            image={modalIconRef.current}
            subtitle={modalSubtitleRef.current}
            title={modalTitleRef.current}
          />
        </DesktopPopupModal>
      )}
    </Box>
  );
};

ConfirmFlightExchange.defaultProps = defaultProps;

export default withRouter(ConfirmFlightExchange);
