import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Experiment, ExperimentState, ExperimentVariant } from "redmond";

import { fetchActiveExperiments } from "../api/v1/experiments/fetchExperiments";

export enum ActiveExperiments {
  ShopPageRedesign = "c1-ops-selfserve-exchange-redesign",
  UseFareRulesService = "c1-agency-use-exchange-fare-rules-service",
  EnableAutomatedExchange = "c1-agency-automated-exchange-book",
}

export interface IExperimentProviderProps {
  children: ReactNode;
  initState: ExperimentState | undefined;
}

const defaultProps: Partial<IExperimentProviderProps> = {
  initState: {
    experiments: [],
    trackingProperties: undefined,
  },
};

export const ExperimentsContext = createContext<ExperimentState | undefined>(
  undefined
);

export function getExperimentVariant(
  experiments: Array<Experiment>,
  experimentId: String
): string {
  const experiment = experiments?.find((exp) => exp.id === experimentId);

  if (experiment) {
    return experiment.variant;
  }
  return ExperimentVariant.CONTROL;
}

export function useExperiments(): ExperimentState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

/**
 * @description Checks to see if a provided experiment matches the target
 * variant
 * @param {ActiveExperiments} experiment The experiment to check
 * @param {string} [target=ExperimentVariant.AVAILABLE] The target variant to
 * compare the experiment's value to
 * @return {boolean}
 */
export function useExperiment(
  experiment: ActiveExperiments,
  target: string = ExperimentVariant.AVAILABLE
) {
  const { experiments } = useExperiments();
  if (!experiments) throw new Error("No experiments found in Context");
  const expVariant = getExperimentVariant(experiments, experiment);

  return expVariant === target;
}

export function addTrackingProperties(
  trackingProperties: Map<string, string> | undefined,
  properties?: any
): unknown {
  const updatedProperties = properties ?? {};

  if (trackingProperties) {
    updatedProperties.experiments = Object.keys(trackingProperties).map(
      (ex) => `${ex}_${trackingProperties[ex]}`
    );
  }

  return updatedProperties;
}

const ExperimentsProvider: FC<IExperimentProviderProps & PropsWithChildren> = (
  props: IExperimentProviderProps
) => {
  const { children, initState } = props;
  const { experiments } = initState!;
  // TODO: use tracking reducers
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!experiments.length) {
      const fetchExperiments = async () => {
        await fetchActiveExperiments().then((result) => {
          setState(result as ExperimentState);
        });
      };
      fetchExperiments();
    }
  }, [experiments]);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

ExperimentsProvider.defaultProps = defaultProps;

export default ExperimentsProvider;
