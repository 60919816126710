import axios from "axios";
import {
  AirExchangeAgentFulfillResponse,
  IAgentFulfillFlightExchangeReq,
  IAgentFulfillFlightExchangeRes,
} from "redmond";

import { config } from "../../config";
import { submitFlightExchangePathV2 } from "../paths";

const submitFlightExchange = (
  req: IAgentFulfillFlightExchangeReq
): Promise<void> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post<IAgentFulfillFlightExchangeRes>(
        submitFlightExchangePathV2,
        req,
        {
          baseURL: config.baseURL,
        }
      );

      if (
        res.data.AirExchangeAgentFulfillResponse ===
        AirExchangeAgentFulfillResponse.success
      ) {
        resolve();
      } else {
        reject();
      }
    } catch (e) {
      reject(e);
    }
  });

export default submitFlightExchange;
