import { CurrencyFormatters } from "halifax";
import { AlgomerchTag, Amount, FlightSortOption, ISortOptions } from "redmond";

export const PAGE_SIZE = 5;

export const algomerchTitles = Object.freeze({
  [AlgomerchTag.Cheapest]: {
    description: "Travel more (for less).",
    factors:
      "We sift through hundreds of <b>flight prices</b> to find the absolute best price.",
    title: "Best Price",
  },
  [AlgomerchTag.BestFlight]: {
    description: "Let data decide.",
    factors:
      "<b>Price</b>, <b>quality</b>, and <b>total flight time</b> are considered to find the flight with the highest flight score and best overall value.",
    title: "Best Overall",
  },
  [AlgomerchTag.Fastest]: {
    description: "Get from A to B without losing any Zzz’s.",
    factors:
      "<b>Duration</b> and <b>number of stops</b> are considered to identify the fastest flight.",
    title: "Fastest",
  },
  [AlgomerchTag.BestQuality]: {
    description: "Sit back. Stretch out.",
    factors:
      "<b>Airline</b>, <b>fare class</b>, and <b>stops</b> are considered to find the flight with the comfiest flying experience. ",
    title: "Best Quality",
  },
});

export const filterCopy = {
  AIRLINE: "Airline",
  AIRPORT: "Airport",
  ANY_NUMBER_OF_STOPS: "Any number of stops",
  CHANGE_STOPS_FILTER: "Change stops filter",
  FILTER_STOPS: "Filter stops",
  FLIGHT_NUMBER: "Flight #",
  HEADER: (departureDate: string) =>
    `Select an Airline to view flight numbers departing on ${departureDate}`,
  NO_STOPS: "Nonstop only",
  ONE_STOP_OR_FEWER: "One stop or fewer",
  PRICE: "Price",
  SET_MAX_PRICE: "Set max price",
  STOPS: "Stops",
  TWO_STOPS_OR_FEWER: "Two stops or fewer",
};

export const flightShopCopy = Object.freeze({
  ALGOMERCH_INFO_TITLE: "How are flights scored?",
  ARRIVAL: "Arrival",
  BANNER_SUBTITLE: (airline: string) =>
    `Take a look at some replacement flights${
      airline ? ` from ${airline}` : ""
    }.`,
  BANNER_TITLE: "Change of plans?",
  BANNER_TITLE_FTC: "Redeem Your Travel Credit",
  BEST_FLIGHT: "Best Overall",
  BEST_QUALITY: "Best Quality",
  CHEAPEST: "Best Price",
  CHOOSE_SAME_OPTS:
    "Choose from the same options available to our customer support team",
  DEPARTURE: "Departure",
  ERROR_SUBTITLE:
    "Check to see if you are connected to a network right now or try reloading.",
  ERROR_TITLE: "Oops...something went wrong!",
  FASTEST: "Fastest",
  NO_FARE_CLASS_FLIGHTS_SUBTITLE:
    "There are no flights available in this fare category.",
  NO_FILTERED_FLIGHTS_SUBTITLE: "Try searching again or modifying your filters",
  NO_FLIGHTS_SUBTITLE: "There are no flights available.",
  NO_FLIGHTS_TITLE: "We couldn't find any flights",
  PREV_SELECTED_TITLE: (onReturnStep: boolean = false, redesignExp = false) =>
    `Your ${redesignExp ? "current" : "previously selected"} ${
      onReturnStep ? "return" : "outbound"
    } flight`,
  PRICE_PER_TRAVELER: "Price per traveler:",
  SAME_FLIGHT_OPTIONS:
    "These are the same flight options available to customer service agents.",
  REVIEW_ITINERARY_SUBTITLE:
    "Review the details of your trip before you continue",
  REVIEW_ITINERARY_TITLE: "Review itinerary",
  SEARCHING_FOR_FLIGHTS_TITLE: "for flights",
  SELECT_NEW_FLIGHT_SUBTITLE: (airline: string, changeFee: number) =>
    `You can make changes to your upcoming trip with ${airline} for a fee of <b>${CurrencyFormatters.get().format(
      changeFee
    )}</b> per traveler plus any fare difference.`,
  SELECT_NEW_FLIGHT_TITLE: (onReturnStep: boolean = false) =>
    `Select your new ${onReturnStep ? "return" : "outbound"} flight`,
  STOPS: (numStops: number = 0) => {
    if (numStops === 0) return "Nonstop";
    if (numStops === 1) return "1 stop";
    return `${numStops} stops`;
  },
  TIME: "Time",
  TIMES_OUTBOUND: "Times - Outbound",
  TIMES_RETURN: "Times - Return",
});

export const fareClasses = Object.freeze({
  BASIC: {
    desc: "Typically the best priced options. These are basic fares with restrictive policies and few amenities.",
    tag: "basic",
    title: "Basic",
  },
  ENHANCED: {
    desc: "These fares may include more legroom, priority boarding, free beverages and snacks.",
    tag: "enhanced",
    title: "Enhanced",
  },
  LUXURY: {
    desc: "Luxury reclined seats with personalized services and premium meals. Priority boarding also included.",
    tag: "luxury",
    title: "Luxury",
  },
  PREMIUM: {
    desc: "These fares include cradle or reclined seats, priority boarding, premium snacks and beverages.",
    tag: "premium",
    title: "Premium",
  },
  STANDARD: {
    desc: "Typically main and economy fares. These may include amenities like carry-on bags and seat selection.",
    tag: "standard",
    title: "Standard",
  },
});

export const reviewCopy = Object.freeze({
  COMBINATION_HEADER: "Your trip is a combination of 2 one-way fares.",
  COMBINATION_SUBHEADER: "Restrictions may differ between airlines.",
  HEADER: "Ready to book?",
  NEW_PAX_PRICING: (price: Amount) =>
    `<b>${CurrencyFormatters.get(price.currency).format(
      price.amount
    )}</b> or ${(price.amount * 100).toLocaleString()} miles`,
  NEW_PAX_SUBTITLE: (isRoundTrip: boolean = false) =>
    `${isRoundTrip ? "Round-trip" : "One-way"}, per traveler`,
  PAX_PRICING_DIFF: (diff: Amount) =>
    `The price per traveler has increased by <b>${CurrencyFormatters.get(
      diff.currency
    ).format(diff.amount)}</b> / ${(
      diff.amount * 100
    ).toLocaleString()} miles.`,
  SUBHEADER: "Here are some details you should know about.",
});

export const scoreFactorTitles = {
  airline: "Airline",
  fareClass: "Fare Class",
  price: "Price",
  duration: "Duration",
  stops: "Stops",
};

export const sortOptions: ISortOptions[] = [
  {
    label: "Recommended",
    value: FlightSortOption.FareScore,
  },
  {
    label: "Price (Low to High)",
    value: FlightSortOption.Price,
  },
  {
    label: "Depart (Earliest to Latest)",
    value: FlightSortOption.DepartureTime,
  },
  {
    label: "Arrive (Earliest to Latest)",
    value: FlightSortOption.ArrivalTime,
  },
  {
    label: "Stops (Least to Most)",
    value: FlightSortOption.Stops,
  },
  {
    label: "Duration (Shortest to Longest)",
    value: FlightSortOption.Duration,
  },
];

export enum FlightCardType {
  content = "content",
  skeleton = "skeleton",
}

export enum ModalType {
  AlgomerchInfo,
  DateRangePicker,
  MobileFlightDetails,
}

export enum ExchangeTypeEventEnum {
  DatesOnly = "dates_only",
  AirportAndDates = "airport_and_dates",
  AirportOnly = "airport_only",
  Same = "same",
}
