import axios from "axios";
import { TripDetails } from "redmond";

import { config } from "../../config";
import { exchangeTripDetailsPathV2 } from "../paths";

export const fetchTripDetailsV2 = async (
  tripId: string,
  isHackerFareReturn: boolean | undefined
) => {
  try {
    const value: string = tripId;
    const req = { value, isHackerFareReturn };
    const res = await axios.post<TripDetails>(exchangeTripDetailsPathV2, req, {
      baseURL: config.baseURL,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
