import { Box, Button } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionButton,
  ActionLink,
  B2BSpinner,
  B2BSpinnerWithText,
  CloseButtonIcon,
  DesktopPopupModal,
  GenericModalContent,
  Icon,
  IconName,
  MobilePopoverCard,
  NoResults,
  useDeviceTypes,
} from "halifax";
import { uniqBy } from "lodash";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { batch, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  AlgomerchTag,
  ExchangeScenario,
  FareClasses,
  FlightRatingsEnum,
  FlightShopStep,
  IFlightListData,
  IShoppedTrip,
  mapAlgomerchTexts,
  Maybe,
  MultiTicketTypeEnum,
  SelfServeEvents,
  TripCategory,
  Uuid,
  VIEWED_SLICE,
} from "redmond";

import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import {
  buttonText,
  confirmCopy,
  flightShopCopy,
  ModalState,
  ModalType,
  PAGE_SIZE,
} from "../../../../constants";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../context/experiments";
import {
  fetchTripDetails,
  fetchTripSummaries,
  resetAllFilters,
  setFareClassesFilter,
  setOutboundFareClass,
  setSelectedOutgoingSlice,
  setSelectedReturnSlice,
  setShopStep,
} from "../../../../reducers/flightShop";
import {
  getExchangeScenario,
  getExchangeType,
  getExchangeTypeEvent,
  getFareClassFilterApplied,
  getFlightBooking,
  getFlights,
  getFlightShopStep,
  getHasTripSummariesError,
  getIsTripSummariesLoading,
  getMultiTicketType,
  getNonFareClassFilterApplied,
  getOriginalExchangeFee,
  getCartQuoteId,
  getSessionId,
  getShoppedTrip,
  getSortedFlights,
  getTripDetailsMap,
  getTripType,
} from "../../../../selectors";
import { getSliceFareDetails, skipShopAction } from "../../../../utils/helpers";
import {
  PATH_FLIGHT_CONFIRM,
  PATH_FLIGHT_CONFIRM_AUTOMATED,
  PATH_FLIGHT_EXCHANGE,
  PATH_FLIGHT_SHOP,
} from "../../../../utils/paths";
import { FlightAlgomerchModal } from "../FlightAlgomerchModal";
import { FlightListItem } from "../FlightListItem";
import { SkeletonFlightListItem } from "../SkeletonFlightListItem";

import "./styles.scss";
import scheduleExchangePriceQuote from "../../../../api/v1/exchange/scheduleExchangePriceQuote";
import {
  setIdempotencyKey,
  setPriceQuote,
  setCartQuoteId,
  setSessionId,
} from "../../../../reducers/flightBook";
import pollExchangePriceQuote from "../../../../api/v1/exchange/pollExchangePriceQuote";

export interface IFlightListProps extends RouteComponentProps {}

const defaultProps: Partial<IFlightListProps> = {};

const FlightList = (props: IFlightListProps): JSX.Element => {
  const { history } = props;
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();
  const isAutomatedExchangeEnabled = useExperiment(
    ActiveExperiments.EnableAutomatedExchange
  );

  const exchangeType = useSelector(getExchangeType);
  const fareClassFilterApplied = useSelector(getFareClassFilterApplied);
  const flights = useSelector(getFlights);
  const hasTripSummariesError = useSelector(getHasTripSummariesError);
  const isTripSummariesLoading = useSelector(getIsTripSummariesLoading);
  const nonFareClassFilterApplied = useSelector(getNonFareClassFilterApplied);
  const scenario = useSelector(getExchangeScenario);
  const shopStep = useSelector(getFlightShopStep);
  const sortedFlightIds = useSelector(getSortedFlights);
  const tripDetailsMap = useSelector(getTripDetailsMap);
  const tripType = useSelector(getTripType);
  const multiTicketType = useSelector(getMultiTicketType);
  const booking = useSelector(getFlightBooking);
  const sessionId: Maybe<Uuid> = useSelector(getSessionId);
  const cartQuoteId: Uuid | undefined = useSelector(getCartQuoteId);
  const shoppedTrip = useSelector(getShoppedTrip);
  const exchangeTypeEvent = useSelector(getExchangeTypeEvent);
  const ogChangeFee = useSelector(getOriginalExchangeFee);

  const [expandedFlightId, setExpandedFlightId] = useState("");
  const [fareTrips, setFareTrips] = useState<any[]>([]);
  const [flightsToShow, setFlightsToShow] = useState<IFlightListData[]>(
    sortedFlightIds.slice(0, PAGE_SIZE)
  );
  const [openModal, setOpenModal] = useState<ModalType>();
  const [priceQuoteFailureModalOpen, setPriceQuoteFailureModalOpen] =
    useState(false);
  const [selectedAlgomerchTag, setSelectedAlgomerchTag] = useState(
    AlgomerchTag.Cheapest
  );
  const [failureCount, setFailureCount] = useState(0);
  const [selectedFareId, setSelectedFareId] = useState("");
  const [modalState, setModalState] = useState(ModalState.Closed);

  const hideXButtonRef = useRef(true);
  const modalActionsRef = useRef<ReactNode>(null);
  const modalIconRef = useRef<ReactNode>(null);
  const modalSubtitleRef = useRef("");
  const modalTitleRef = useRef("");

  const closeLoadingModal = useCallback(() => {
    setModalState(ModalState.Closed);
  }, []);

  const renderLoadingOrProcessing = (title: string, subtitle: string) => (
    <B2BSpinnerWithText subtitle={subtitle} title={title} />
  );

  const getPriceQuoteLoadingModal = useCallback(() => {
    hideXButtonRef.current = true;
    return renderLoadingOrProcessing(
      confirmCopy.PRICE_QUOTE_LOADING_TITLE,
      confirmCopy.PRICE_QUOTE_LOADING_SUBTITLE
    );
  }, []);

  const onPriceQuoteFailureModalClose = (ev: MouseEvent, reason: string) => {
    if (reason !== "backdropClick") {
      ev?.stopPropagation?.();
      setPriceQuoteFailureModalOpen(false);
      dispatch(resetAllFilters());
      dispatch(setShopStep(FlightShopStep.ChooseDeparture));
      history.push(PATH_FLIGHT_SHOP);
    }
  };

  const PriceQuoteModalContent = getPriceQuoteLoadingModal();
  const originalReservationId = booking!.bookedItinerary.id;
  const shoppedTripFareId =
    shoppedTrip.returnFareId || shoppedTrip.outgoingFareId;

  // only bypass review for exchange. Do not bypass for FTC Redeem
  const bypassReview = scenario !== ExchangeScenario.ftc;
  const onReturnStep = shopStep === FlightShopStep.ChooseReturn;
  const isHackerFare = multiTicketType == MultiTicketTypeEnum.HackerFare;
  const isMultiTravelItinerary = multiTicketType !== MultiTicketTypeEnum.Single;

  const expandedFareDetails = useMemo(() => {
    const fetchedAllFareDetails = fareTrips.length
      ? fareTrips.every((fareTrip) => !!tripDetailsMap[fareTrip.trip])
      : false;

    return fetchedAllFareDetails
      ? getSliceFareDetails(tripDetailsMap, fareTrips)
      : null;
  }, [fareTrips, tripDetailsMap]);

  const onAlgomerchTagClick = (tagText: string) => {
    const allTags = Object.keys(AlgomerchTag);
    const selectedTag =
      allTags.find((tag) => tagText.includes(mapAlgomerchTexts[tag])) ??
      AlgomerchTag.Cheapest;

    setSelectedAlgomerchTag(selectedTag as AlgomerchTag);
  };

  const onExpandItem = (
    sliceId: string,
    selectedFareClass: string,
    flight: IFlightListData
  ) => {
    if (sliceId === expandedFlightId) {
      setExpandedFlightId("");
    } else {
      const flightFares = flight.fares.map((fare: any) => {
        if (!onReturnStep) return fare.example;
        return { fare: fare.id, trip: fare.tripId };
      });

      uniqBy(flightFares, (trip) => trip.trip).map((fareTrip) =>
        dispatch(
          fetchTripDetails({
            tripId: fareTrip.trip,
            isHackerFareReturn: isHackerFare && onReturnStep,
          })
        )
      );

      setExpandedFlightId(sliceId);
      setFareTrips(flightFares);
      trackEvent({
        eventName: VIEWED_SLICE,
        properties: {
          // ...viewedForecastProperties,
          fareClass: selectedFareClass || "",
        },
      });
    }
  };

  const searchAgain = () => {
    dispatch(
      fetchTripSummaries({
        isMobile: matchesMobile,
      })
    );
  };

  const selectFare = (flight: any, fareId: string) => {
    setExpandedFlightId("");

    if (onReturnStep) {
      const fare = flight.fares.find((f: any) => f.id === fareId)?.tripId ?? "";
      const tripDetails = tripDetailsMap[fare];
      const fareDetails = tripDetails?.fareDetails.find(
        (fd) => fd.id === fareId
      );

      batch(() => {
        dispatch(
          setSelectedReturnSlice({
            returnFareId: fareId,
            returnFareRating: fareDetails?.slices[1].fareShelf?.rating,
            returnSliceId: flight.slice,
            tripId: tripDetails?.id,
          })
        );

        if (bypassReview) {
          if (isAutomatedExchangeEnabled && !isMultiTravelItinerary) {
            schedulePriceQuote(tripDetails?.id, fareId);
          } else {
            history.push({
              pathname: PATH_FLIGHT_CONFIRM,
              search: history.location.search,
            });
          }
        } else {
          dispatch(setShopStep(FlightShopStep.ReviewItinerary));
        }
      });
    } else {
      const selectedFareIdx = flight.fares.findIndex(
        (fare: any) => fare.example.fare === fareId
      );
      const fareSliceId = flights!.fares[fareId].outbound;
      const tripId = flight.fares[selectedFareIdx].example.trip;

      const selectedFareClass =
        flights!.fareSlices[fareSliceId].fareShelf?.value;
      dispatch(setOutboundFareClass(selectedFareClass));

      const selectedSlice: Partial<IShoppedTrip> = {
        outgoingFareId: fareId,
        outgoingFareRating: selectedFareClass,
        outgoingSliceId: flight.slice,
        tripId,
      };
      const skipChooseReturn = skipShopAction(exchangeType.returnSelection);

      // also update return values if that step isn't getting skipped
      if (!skipChooseReturn) {
        const trip = flights?.trips[tripId];

        if (trip?.fares.length === 1) {
          const tripDetails = tripDetailsMap[tripId];

          selectedSlice.returnFareId = trip.fares[0];
          selectedSlice.returnFareRating =
            tripDetails?.fareDetails[0].slices[1]?.fareShelf?.rating;
          selectedSlice.returnSliceId = trip?.return;
        }
      }

      batch(() => {
        dispatch(setSelectedOutgoingSlice(selectedSlice));
        dispatch(resetAllFilters());

        const fareClassesFilter: FareClasses = {
          basic: false,
          enhanced: false,
          luxury: false,
          premium: false,
          standard: false,
        };

        const fareClass = FlightRatingsEnum[selectedFareClass];
        const fareClasses = {
          ...fareClassesFilter,
          [fareClass]: !fareClassesFilter[fareClass],
        };
        dispatch(setFareClassesFilter(fareClasses));

        if (skipChooseReturn || tripType === TripCategory.ONE_WAY) {
          if (bypassReview) {
            if (isAutomatedExchangeEnabled && !isMultiTravelItinerary) {
              schedulePriceQuote(tripId, fareId);
            } else {
              history.push({
                pathname: PATH_FLIGHT_CONFIRM,
                search: history.location.search,
              });
            }
          } else {
            dispatch(setShopStep(FlightShopStep.ReviewItinerary));
          }
        } else {
          dispatch(setShopStep(FlightShopStep.ChooseReturn));
        }
      });
    }
  };

  const setFetchMoreData = () => {
    const newPageSize = flightsToShow.length + PAGE_SIZE;

    return setTimeout(
      () => setFlightsToShow(sortedFlightIds.slice(0, newPageSize)),
      500
    );
  };

  const schedulePriceQuote = async (
    tripId: string,
    fareId: string,
    subBookingLocator?: string,
    isWithinVoidWindow?: boolean
  ) => {
    setModalState(ModalState.PriceQuoteLoadingOrProcessing);

    return scheduleExchangePriceQuote(
      originalReservationId,
      tripId,
      fareId,
      subBookingLocator,
      isWithinVoidWindow
    )
      .then((response) => {
        dispatch(setSessionId(response.sessionId!));
        dispatch(setCartQuoteId(response.cartQuoteId));
        dispatch(setIdempotencyKey(response.idempotencyKey));
      })
      .catch(() => {
        trackEvent({
          eventName:
            scenario === ExchangeScenario.ftc
              ? SelfServeEvents.FTCExchangePriceQuoteFailure
              : SelfServeEvents.ExchangePriceQuoteFailure,
          properties: {
            url: window.location.pathname,
            exchange_type: exchangeTypeEvent,
            exchange_fee: ogChangeFee.amount,
          },
        });

        modalIconRef.current = (
          <Icon className="failure-icon" name={IconName.ErrorState} />
        );
        modalTitleRef.current = confirmCopy.ISSUE_SUBMITTING_REQ;
        modalSubtitleRef.current = confirmCopy.SUBMIT_TRY_AGAIN;
        modalActionsRef.current = (
          <ActionButton
            className="contact-support-btn"
            message={buttonText.TRY_AGAIN}
            onClick={() => {
              setPriceQuoteFailureModalOpen(false);
              schedulePriceQuote(tripId, fareId);
            }}
          />
        );
        setPriceQuoteFailureModalOpen(true);
      })
      .finally(() => {});
  };

  const pollPriceQuote = async () => {
    return pollExchangePriceQuote(sessionId!, cartQuoteId!)
      .then((pollQuoteResponse) => {
        if (pollQuoteResponse.priceQuote) {
          dispatch(setPriceQuote(pollQuoteResponse.priceQuote));
          history.push({
            pathname: PATH_FLIGHT_CONFIRM_AUTOMATED,
            search: history.location.search,
          });
        }
      })
      .catch(() => {
        setFailureCount(failureCount + 1);

        trackEvent({
          eventName:
            scenario === ExchangeScenario.ftc
              ? SelfServeEvents.FTCExchangePriceQuoteFailure
              : SelfServeEvents.ExchangePriceQuoteFailure,
          properties: {
            url: window.location.pathname,
            exchange_type: exchangeTypeEvent,
            exchange_fee: ogChangeFee.amount,
          },
        });

        // Unless price quote is updated to have a better chance of succeeding on a retry,
        // we should direct to manual agent fulfillment after a single failure instead of retrying
        if (failureCount < 0) {
          modalIconRef.current = (
            <Icon className="failure-icon" name={IconName.ErrorState} />
          );
          modalTitleRef.current = confirmCopy.ISSUE_UPDATING_PRICE;
          modalSubtitleRef.current = confirmCopy.SUBMIT_TRY_AGAIN;
          modalActionsRef.current = (
            // TODO - this should have the second button in another column
            <>
              <ActionButton
                className="contact-support-btn"
                message={buttonText.TRY_AGAIN}
                onClick={() => {
                  setPriceQuoteFailureModalOpen(false);
                  schedulePriceQuote(shoppedTrip.tripId!, shoppedTripFareId!);
                }}
              />
              <ActionButton
                className="contact-support-btn"
                message={buttonText.SEARCH_AGAIN}
                onClick={() => {
                  setPriceQuoteFailureModalOpen(false);
                  dispatch(resetAllFilters());
                  dispatch(setShopStep(FlightShopStep.ChooseDeparture));
                  history.push(PATH_FLIGHT_SHOP);
                }}
              />
            </>
          );
        } else {
          // After set number of failures we should direct to the manual agent fulfillment
          history.push({
            pathname: PATH_FLIGHT_CONFIRM,
            search: history.location.search,
          });
        }
        setPriceQuoteFailureModalOpen(true);
      })
      .finally(() => {
        closeLoadingModal();
      });
  };

  const renderFlights = useCallback(
    () => (
      <InfiniteScroll
        dataLength={flightsToShow.length}
        hasMore={flightsToShow.length < sortedFlightIds.length}
        loader={
          <Box className="loading-flights">
            <B2BSpinner classes={["loading-flights-bunny"]} />
          </Box>
        }
        next={setFetchMoreData}
      >
        {flightsToShow.map((flight) => {
          const { slice: sliceId } = flight;
          const slice = flights!.slices[sliceId];

          if (slice) {
            return (
              <FlightListItem
                expandedFareDetails={expandedFareDetails}
                expandedFlightId={expandedFlightId}
                flight={flight}
                isInternational={!slice.domestic}
                isReturn={onReturnStep}
                key={sliceId}
                onAlgomerchTagClick={onAlgomerchTagClick}
                onExpand={onExpandItem}
                onFareClick={setSelectedFareId}
                onFareSelectClick={selectFare}
                selectedFareId={selectedFareId}
                setOpenModal={setOpenModal}
                slice={slice}
                sliceId={sliceId}
              />
            );
          }

          return null;
        })}
      </InfiniteScroll>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      expandedFareDetails,
      expandedFlightId,
      flightsToShow,
      selectedFareId,
      sortedFlightIds,
      flights,
    ]
  );

  const renderNoFlightsMessage = useCallback(() => {
    let CallToAction = null;
    let subtitle: string = flightShopCopy.NO_FLIGHTS_SUBTITLE;
    let title;

    if (hasTripSummariesError) {
      CallToAction = (
        <button className="reload-button" onClick={searchAgain} type="button">
          {buttonText.RELOAD}
        </button>
      );
      subtitle = flightShopCopy.ERROR_SUBTITLE;
      title = flightShopCopy.ERROR_TITLE;
    } else {
      title = flightShopCopy.NO_FLIGHTS_TITLE;

      if (fareClassFilterApplied) {
        subtitle = flightShopCopy.NO_FARE_CLASS_FLIGHTS_SUBTITLE;
      } else if (nonFareClassFilterApplied) {
        subtitle = flightShopCopy.NO_FILTERED_FLIGHTS_SUBTITLE;
      }

      if (matchesMobile) {
        CallToAction = (
          <Button
            aria-label={buttonText.MODIFY_SEARCH}
            className="modify-search-button b2b"
            onClick={() =>
              history.push({
                pathname: PATH_FLIGHT_EXCHANGE,
                search: history.location.search,
              })
            }
            role="button"
          >
            {buttonText.MODIFY_SEARCH}
          </Button>
        );
      } else {
        CallToAction = (
          <Button
            aria-label={buttonText.SEARCH_AGAIN}
            className="search-again-button b2b"
            onClick={searchAgain}
            role="button"
          >
            {buttonText.SEARCH_AGAIN}
          </Button>
        );
      }
    }

    return (
      <Box className="no-results-container">
        <NoResults
          className="flight-list-no-results"
          subtitle={subtitle}
          title={title}
        />
        {CallToAction}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fareClassFilterApplied,
    nonFareClassFilterApplied,
    hasTripSummariesError,
    matchesMobile,
  ]);

  // Poll price quote after schedule updates sessionId
  useEffect(() => {
    if (sessionId) {
      pollPriceQuote();
    }
  }, [sessionId]);

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", left: 0, top: 0 });

    if (sortedFlightIds.length > 0) {
      setFlightsToShow(sortedFlightIds.slice(0, PAGE_SIZE));
    } else {
      setFlightsToShow([]);
    }
  }, [sortedFlightIds]);

  useEffect(() => {
    // close expanded on subsequent searches to force fetching of updated
    // trip details
    if (isTripSummariesLoading) {
      setExpandedFlightId("");
      setFlightsToShow([]);
    }
  }, [isTripSummariesLoading]);

  return (
    <Box
      className={clsx("reshop-flight-list", {
        "flight-list-skeleton": isTripSummariesLoading,
        mobile: matchesMobile,
      })}
    >
      {isTripSummariesLoading ? (
        <SkeletonFlightListItem isMobile={matchesMobile} />
      ) : (
        <>
          {flightsToShow.length > 0
            ? renderFlights()
            : renderNoFlightsMessage()}
        </>
      )}
      {openModal === ModalType.AlgomerchInfo && (
        <FlightAlgomerchModal
          isOpen={openModal === ModalType.AlgomerchInfo}
          isMobile={matchesMobile}
          onClose={() => setOpenModal(undefined)}
          selectedCategory={selectedAlgomerchTag}
          setSelectedCategory={setSelectedAlgomerchTag}
        />
      )}
      {/* Price Quote Loading Modal */}
      {matchesMobile ? (
        <MobilePopoverCard
          centered
          className="flight-exchange-mobile-modal"
          contentClassName="modal-content"
          onClose={() => {
            return;
          }}
          open={modalState == ModalState.PriceQuoteLoadingOrProcessing}
          topRightButton={
            hideXButtonRef.current ? undefined : (
              <ActionLink
                className="close-mobile-modal-btn"
                content={<CloseButtonIcon />}
                label="Close"
                onClick={closeLoadingModal}
              />
            )
          }
        >
          {PriceQuoteModalContent}
        </MobilePopoverCard>
      ) : (
        <DesktopPopupModal
          className="flight-exchange-desktop-modal"
          hideXButton={hideXButtonRef.current}
          invisibleBackdrop={false}
          onClose={() => {
            return;
          }}
          open={modalState == ModalState.PriceQuoteLoadingOrProcessing}
        >
          {PriceQuoteModalContent}
        </DesktopPopupModal>
      )}
      {/* Price Quote failure modal */}
      {priceQuoteFailureModalOpen && matchesMobile ? (
        <MobilePopoverCard
          centered
          disableEscapeKeyDown
          className="submit-request-response-modal mobile"
          contentClassName="modal-content"
          onClose={onPriceQuoteFailureModalClose}
          open={priceQuoteFailureModalOpen}
        >
          <GenericModalContent
            actions={modalActionsRef.current}
            image={modalIconRef.current}
            subtitle={modalSubtitleRef.current}
            title={modalTitleRef.current}
          />
        </MobilePopoverCard>
      ) : (
        <DesktopPopupModal
          disableEscapeKeyDown
          invisibleBackdrop={false}
          className="submit-request-response-modal"
          onClose={onPriceQuoteFailureModalClose}
          open={priceQuoteFailureModalOpen}
        >
          <GenericModalContent
            actions={modalActionsRef.current}
            image={modalIconRef.current}
            subtitle={modalSubtitleRef.current}
            title={modalTitleRef.current}
          />
        </DesktopPopupModal>
      )}
    </Box>
  );
};

FlightList.defaultProps = defaultProps;

export default withRouter(FlightList);
