import { PayloadAction } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";
import { TripDetails } from "redmond";

import { fetchTripDetailsV2 } from "../../api/v1/shop/fetchTripDetailsV2";
import {
  IFetchTripDetailsPayload,
  setTripDetails,
} from "../../reducers/flightShop";
import Logger from "../../utils/logger";

function* fetchTripDetailsSaga(
  action: PayloadAction<IFetchTripDetailsPayload>
) {
  const { tripId, isHackerFareReturn } = action.payload;

  try {
    const tripDetails: TripDetails = yield fetchTripDetailsV2(
      tripId,
      isHackerFareReturn
    );

    yield put(setTripDetails(tripDetails));
  } catch (e) {
    Logger.debug(e);
  }
}

export default fetchTripDetailsSaga;
