import { PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  Amount,
  ExchangeScenario,
  FareClasses,
  FlightShopStep,
  IExchangeFlightSummariesReq,
  IExchangeFlightSummariesRes,
  IShoppedTrip,
  Platform,
  SelfServeEvents,
  ShopFilter,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
import { put, putResolve, select } from "redux-saga/effects";

import { trackEvent } from "../../api/v1/analytics/trackEvent";
import { ExchangeTypeEventEnum, formats } from "../../constants";
import {
  IFetchTripSummariesPayload,
  resetAllFilters,
  setHasTripSummariesError,
  setSelectedOutgoingSlice,
  setShoppedTrip,
  setShopStep,
} from "../../reducers/flightShop";
import { setFlights } from "../../reducers/search";
import {
  getExchangeScenario,
  getExchangeTypeEvent,
  getFareClassesFilter,
  getFetchSummariesParams,
  getNoResultsEventProps,
  getOriginalExchangeFee,
  getShoppedTrip,
} from "../../selectors";
import { skipShopAction } from "../../utils/helpers";
import fetchFlightSummariesV2 from "../../api/v1/shop/fetchFlightSummariesV2";

export function* fetchTripSummariesSaga(
  action: PayloadAction<IFetchTripSummariesPayload>
) {
  const { isMobile } = action.payload;

  try {
    const {
      bookingId,
      departureDate,
      destTerminus,
      exchangeType,
      originTerminus,
      passengersMap,
      returnDate,
      stopsCount,
      tripType,
    } = yield select(getFetchSummariesParams);
    const fareClassFilter: FareClasses = yield select(getFareClassesFilter);
    const shoppedTrip: IShoppedTrip = yield select(getShoppedTrip);
    const skipChooseDeparture = skipShopAction(exchangeType.outboundSelection);

    if (shoppedTrip.tripId) {
      // clear shopped trip on subsequent searches
      yield put(
        setShoppedTrip({
          outgoingFareId: "",
          outgoingSliceId: "",
          tripId: "",
        })
      );

      // reset shop step to beginning of flow
      if (skipChooseDeparture) {
        yield put(setShopStep(FlightShopStep.ChooseReturn));
      } else {
        yield put(setShopStep(FlightShopStep.ChooseDeparture));
      }

      yield put(resetAllFilters());
    }

    const requestBody: IExchangeFlightSummariesReq = {
      departureDate: dayjs(departureDate).format(formats.REQUEST_DATE),
      exchangeType,
      originalReservationId: bookingId,
      passengers: passengersMap,
      platform: isMobile ? Platform.Mobile : Platform.Desktop,
      returnDate:
        returnDate && tripType === TripCategory.ROUND_TRIP
          ? dayjs(returnDate).format(formats.REQUEST_DATE)
          : undefined,
      route: {
        destination: { ...destTerminus.id.code },
        origin: { ...originTerminus.id.code },
      },
      tripFilter: ShopFilter.NoFilter,
    };

    if (Object.values(fareClassFilter).includes(true)) {
      const { basic, enhanced, luxury, premium, standard } = fareClassFilter;
      const filterOutBasic =
        !basic && enhanced && luxury && premium && standard;

      if (filterOutBasic && stopsCount === SliceStopCountFilter.NONE) {
        requestBody.tripFilter = ShopFilter.NonStopNoLCC;
      } else if (filterOutBasic) {
        requestBody.tripFilter = ShopFilter.NoLCC;
      } else if (stopsCount === SliceStopCountFilter.NONE) {
        requestBody.tripFilter = ShopFilter.NonStop;
      }
    }

    const response: IExchangeFlightSummariesRes = yield fetchFlightSummariesV2(
      requestBody
    );

    const { fareSlices, outbound, trips, airlines } = response.flights;
    const numResults = skipChooseDeparture
      ? Object.keys(trips).length
      : outbound.length;

    // set outbound values ahead of time so user can go directly to selecting a return flight
    if (skipChooseDeparture && outbound.length === 1) {
      const {
        fares: [{ example, fareSlice }],
        slice,
      } = outbound[0];

      yield put(
        setSelectedOutgoingSlice({
          outgoingFareId: fareSlice,
          outgoingFareRating: fareSlices[fareSlice]?.fareShelf?.value,
          outgoingSliceId: slice,
          tripId: example.trip,
        })
      );
    }

    yield put(setHasTripSummariesError(false));
    yield putResolve(setFlights(response.flights));
    const exchangeScenario: ExchangeScenario = yield select(
      getExchangeScenario
    );
    const isFtc = exchangeScenario === ExchangeScenario.ftc;

    if (numResults === 0) {
      const noResultsEventProps: unknown = yield select(getNoResultsEventProps);
      trackEvent({
        eventName: isFtc
          ? SelfServeEvents.FTCExchangeViewedNoResults
          : SelfServeEvents.ExchangeViewedNoResults,
        properties: noResultsEventProps,
      });
    }

    const exchangeTypeEvent: ExchangeTypeEventEnum = yield select(
      getExchangeTypeEvent
    );
    const ogChangeFee: Amount = yield select(getOriginalExchangeFee);

    trackEvent({
      eventName: isFtc
        ? SelfServeEvents.ViewedFTCExchangeShopPage
        : SelfServeEvents.ViewedExchangeShopPage,
      properties: {
        url: window.location.pathname,
        exchange_type: exchangeTypeEvent,
        number_results: numResults,
        carrier_results: Object.keys(airlines),
        exchange_fee: ogChangeFee.amount,
      },
    });
  } catch (e) {
    yield put(setHasTripSummariesError(true));
    console.error(e);
  }
}
