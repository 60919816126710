import React, { useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import clsx from "clsx";
import {
  ActionButton,
  B2BSearchAutocomplete,
  DatePickerButton,
  Icon,
  IconName,
} from "halifax";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  ILocationQueryLabel,
  ITripTerminus,
  LocationQueryEnum,
  Maybe,
  TripCategory,
} from "redmond";

import { FlightDateRangePicker } from "../../../FlightDateRangePicker";
import fetchLocations from "../../../../api/v1/exchange/fetchLocations";
import { AppliedFilterTags } from "./components/AppliedFilterTags";
import { FlightReshopSearchFilter } from "./components/FlightReshopSearchFilter";
import { buttonText, formats, searchCopy } from "../../../../constants";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../context/experiments";
import { fetchTripSummaries } from "../../../../reducers/flightShop";
import { setDestination, setOrigin } from "../../../../reducers/search";
import { getShoppedParams, getTripType } from "../../../../selectors";
import { extractCode } from "../../../../utils/helpers";

import "./styles.scss";

export interface IShopSearchControlProps {
  disabled: boolean;
  isMedDesktop: boolean;
  isMobile: boolean;
}

const defaultProps: Partial<IShopSearchControlProps> = {
  disabled: false,
  isMedDesktop: false,
  isMobile: false,
};

const ShopSearchControl = (props: IShopSearchControlProps): JSX.Element => {
  const { disabled, isMedDesktop, isMobile } = props;
  const dispatch = useDispatch();
  const redesignExp = useExperiment(ActiveExperiments.ShopPageRedesign);

  const shoppedParams = useSelector(getShoppedParams);
  const tripType = useSelector(getTripType);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [destLoading, setDestLoading] = useState(false);
  const [destOpts, setDestOpts] = useState<any[]>([]);
  const [originLoading, setOriginLoading] = useState(false);
  const [originOpts, setOriginOpts] = useState<any[]>([]);
  const [originalDepDate] = useState(shoppedParams.departureDate);
  const [originalDest] = useState(shoppedParams.destination);
  const [originalOrigin] = useState(shoppedParams.origin);
  const [originalRetDate] = useState(shoppedParams.returnDate);

  const searchParmsChanged = useMemo(() => {
    const { departureDate, destination, origin, returnDate } = shoppedParams;

    return (
      !departureDate?.isSame(originalDepDate) ||
      destination !== originalDest ||
      origin !== originalOrigin ||
      !returnDate?.isSame(originalRetDate)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppedParams]);

  const fetchLocationCategories = debounce(
    (searchText: string, forOrigin: boolean = false) => {
      const requestBody: ILocationQueryLabel = {
        LocationQuery: LocationQueryEnum.Label,
        l: searchText,
      };

      if (forOrigin) {
        setOriginLoading(true);
      } else {
        setDestLoading(true);
      }

      fetchLocations(requestBody)
        .then((res: any) => {
          const { categories } = res;

          if (forOrigin) setOriginOpts(categories);
          else setDestOpts(categories);
        })
        .catch(() => {
          if (forOrigin) setOriginOpts([]);
          else setDestOpts([]);
        })
        .finally(() => {
          if (forOrigin) setOriginLoading(false);
          else setDestLoading(false);
        });
    },
    300
  );

  const getOptionSelected = (
    option: Maybe<ITripTerminus>,
    value: Maybe<ITripTerminus>
  ) => !!option && !!value && extractCode(option) === extractCode(value);

  const searchAgain = () => {
    dispatch(fetchTripSummaries({ isMobile }));
  };

  const onValueChange =
    (setStateFn: ActionCreatorWithPayload<ITripTerminus | null>) =>
    (newTerminus: ITripTerminus | null) => {
      if (newTerminus) {
        dispatch(setStateFn(newTerminus));
      }
    };

  return (
    <Box
      className={clsx("flight-reshop-search-control-root", {
        "med-desktop": isMedDesktop,
        redesign: redesignExp,
      })}
    >
      <Box className="flight-reshop-search-container">
        <Box className="flight-reshop-search">
          <Box className="control-row top-row">
            <B2BSearchAutocomplete
              className="origin-autocomplete b2b"
              customIcon={
                <Icon aria-hidden name={IconName.B2BMapPin} ariaLabel="" />
              }
              disabled={disabled}
              fetchValueCategories={(searchText) =>
                fetchLocationCategories(searchText, true)
              }
              getOptionSelected={getOptionSelected}
              label={searchCopy.WHERE_FROM}
              loading={originLoading}
              setValue={onValueChange(setOrigin)}
              value={shoppedParams.origin}
              valueCategories={originOpts}
            />
            <B2BSearchAutocomplete
              className="destination-autocomplete b2b"
              customIcon={
                <Icon aria-hidden name={IconName.B2BMapPin} ariaLabel="" />
              }
              disabled={disabled}
              fetchValueCategories={fetchLocationCategories}
              getOptionSelected={getOptionSelected}
              label={searchCopy.WHERE_TO}
              loading={destLoading}
              setValue={onValueChange(setDestination)}
              value={shoppedParams.destination}
              valueCategories={destOpts}
            />
            <DatePickerButton
              classes={["date-inputs"]}
              dateFormat={formats.DISPLAY_DATE}
              endDate={shoppedParams?.returnDate?.toDate() ?? null}
              endLabel={searchCopy.RETURN}
              hideEndDate={tripType === TripCategory.ONE_WAY}
              onClick={() => setCalendarOpen(true)}
              renderCalendarPopup={(popupProps) => (
                <FlightDateRangePicker
                  {...popupProps}
                  closePicker={() => setCalendarOpen(false)}
                  destinationCode={shoppedParams.destinationCode}
                  isMobile={isMobile}
                  open={calendarOpen}
                  originCode={shoppedParams.originCode}
                />
              )}
              startDate={shoppedParams?.departureDate?.toDate() ?? null}
              startLabel={searchCopy.DEPARTURE}
            />
            {searchParmsChanged && !disabled && (
              <ActionButton
                className="flight-shop-search-button b2b"
                onClick={searchAgain}
                message={buttonText.SEARCH_AGAIN}
              />
            )}
          </Box>
          {redesignExp ? null : (
            <>
              <Box className="control-row middle-row">
                <FlightReshopSearchFilter isMobile={isMobile} />
              </Box>
              <Box className="control-row bottom-row">
                <AppliedFilterTags />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ShopSearchControl.defaultProps = defaultProps;

export default ShopSearchControl;
