import dayjs from "dayjs";
import { formatInterval } from "halifax";
import _, { groupBy, mapValues } from "lodash";
import { useHistory } from "react-router";
import {
  BookedFlight,
  ExchangeAction,
  ExchangeActionEnum,
  FareDetails,
  FareSliceDetails,
  FlightItinerarySlice,
  IBucketedDate,
  IDateBucket,
  IFlightExchangePolicyRes,
  IMonthBucket,
  IPartialAirportMap,
  ITimeRange,
  ITripDetails,
  ITripTerminus,
  Maybe,
  PassengerKey,
  TripDetails,
  TripSlice,
} from "redmond";

import { DAY_IN_MINUTES, formats, searchCopy, SUPPORT_URL } from "../constants";
import { TRIPS_HOME } from "./paths";

export const extractCode = (terminus: Maybe<ITripTerminus>) =>
  terminus?.id?.code.code ?? "";

export const isTimeRangeModified = (timeRange: ITimeRange) => {
  const { max, min } = timeRange;

  return min !== 0 || max !== DAY_IN_MINUTES;
};

/**
 *
 * @param {string} attr
 * @param {IFlightExchangePolicyRes} policy
 * @return {any}
 * @see https://github.com/microsoft/TypeScript/issues/24553#issuecomment-393903220
 *  - `"<field>" in exchangePolicy` needed because of `policy` is union type
 */
export const getPolicyAttr = <T = any>(
  attr: string,
  policy?: IFlightExchangePolicyRes | null
) => {
  if (policy && attr in policy) return policy[attr] as T;

  return null;
};

/**
 * @description Parses the provided itinerary and build a passenger key map
 * @param {BookedFlight} itinerary
 * @return {Partial<Record<PassengerKey, number>>} Values are guaranteed to
 * be >= 1 or undefined
 */
export const getPassengerCountsFromItinerary = (
  itinerary?: BookedFlight
): Partial<Record<PassengerKey, number>> => {
  if (!itinerary) return {};

  const { alone, withLapInfants } = itinerary.passengers;
  const passengerMap: any = groupBy(alone, "type");

  passengerMap[PassengerKey.lapInfant] = withLapInfants;

  return mapValues(
    passengerMap,
    (passengers) => passengers.length || undefined
  );
};

export const getPlusDays = (trip: TripSlice): number =>
  trip.segmentDetails
    .filter((s) => s.plusDays && s.plusDays > 0)
    .reduce((total, segment) => total + (segment.plusDays ?? 0), 0);

export const getReviewCardHeader = (
  isDeparture: boolean,
  location: string,
  date: Date | string,
  parsable = false
) =>
  // Added semicolon to parse into two phrases
  `${isDeparture ? searchCopy.OUTBOUND : searchCopy.RETURN} to ${location} ${
    parsable ? ":" : ""
  } on ${dayjs(date).format(formats.DISPLAY_DATE)}`;

export const getReviewCardHeaderWithType = (
  isDeparture: boolean,
  location: string,
  date: dayjs.Dayjs
) => ({
  description: `to ${location} on ${date.format(formats.DISPLAY_DATE)}`,
  type: isDeparture ? searchCopy.DEPARTURE : searchCopy.RETURN,
});

export const getSliceIndex = (
  departure: boolean,
  details: TripDetails | FareDetails
): number =>
  details.slices.findIndex((slice: TripSlice | FareSliceDetails) =>
    departure ? slice.outgoing : !slice.outgoing
  );

export const getSliceFareDetails = (
  tripDetailsMap: ITripDetails,
  fareTrips: any[]
): TripDetails | null => {
  if (!fareTrips.length) return null;

  const sliceDetails: TripDetails | null = {
    ...tripDetailsMap[fareTrips[0].trip],
    fareDetails: [],
  } as TripDetails;

  fareTrips.forEach((fareTrip: any) => {
    const fareDetailByFareId = tripDetailsMap[fareTrip.trip].fareDetails.find(
      (fareDetail) => fareDetail.id === fareTrip.fare
    );
    if (fareDetailByFareId && sliceDetails)
      sliceDetails.fareDetails.push(fareDetailByFareId);
  });

  return sliceDetails as TripDetails;
};

export const getStopsString = (stops: number) => {
  if (stops === 0) return "Nonstop";
  if (stops > 1) return `${stops} stops`;

  return "1 stop";
};

/**
 * @param {IDateBucket[]} dateBuckets
 * @return {IMonthBucket[]}
 */
export const transformDateBuckets = (
  dateBuckets: IDateBucket[]
): IMonthBucket[] => {
  const processedMonths = dateBuckets.reduce(
    (months, { dates }, bucketIndex) => {
      const reducedMonths = dates.reduce(
        (monthBuckets: IMonthBucket[], dateString: string) => {
          const date = dayjs(dateString).toDate();
          const bucketedDate: IBucketedDate = { bucket: bucketIndex, date };
          const currentMonthIndex = dayjs(date).month();
          let existingMonthBucket = monthBuckets.find(
            ({ monthIndex }) => monthIndex === currentMonthIndex
          );

          if (typeof existingMonthBucket === "undefined") {
            existingMonthBucket = { monthIndex: currentMonthIndex, dates: [] };
            monthBuckets.push(existingMonthBucket);
          }

          existingMonthBucket.dates.push(bucketedDate);

          return monthBuckets;
        },
        months
      );

      return reducedMonths;
    },
    [] as IMonthBucket[]
  );
  const sortedMonths = processedMonths.map((processedMonth) => {
    const sortedDates = processedMonth.dates.sort(
      (a: IBucketedDate, b: IBucketedDate) => dayjs(a.date).diff(b.date)
    );
    return { monthIndex: processedMonth.monthIndex, dates: sortedDates };
  });

  return sortedMonths;
};

export const getFlightDuration = (slice?: FlightItinerarySlice): string => {
  if (!slice) return "";
  const {
    scheduledDeparture,
    updatedDeparture,
    zonedScheduledDeparture,
    zonedUpdatedDeparture,
  } = slice.segments[0];
  const {
    scheduledArrival,
    updatedArrival,
    zonedScheduledArrival,
    zonedUpdatedArrival,
  } = _.last(slice.segments) || slice.segments[0];
  const prioritizedDepartureTime =
    zonedUpdatedDeparture ||
    updatedDeparture ||
    zonedScheduledDeparture ||
    scheduledDeparture ||
    "";
  const prioritizedArrivalTime =
    zonedUpdatedArrival ||
    updatedArrival ||
    zonedScheduledArrival ||
    scheduledArrival ||
    "";
  return formatInterval(
    dayjs(prioritizedArrivalTime).diff(prioritizedDepartureTime, "minute", true)
  );
};

export const getAirportCityName = (
  airports: IPartialAirportMap,
  code?: string
): string => (code ? airports[code]?.cityName ?? code : "");

export const openSupportInNewTab = () =>
  window.open(SUPPORT_URL, "_blank")?.focus();

export const goBackToMyTrips = (
  // history: { location: Location, push: (arg0: any) => void },
  history: ReturnType<typeof useHistory>,
  search?: string
) => {
  let myTripsSearchParams = search;

  if (myTripsSearchParams == null) {
    const { location } = history;
    const searchParams = new URLSearchParams(location.search);
    const bookingId = searchParams.get("bookingId");

    myTripsSearchParams = `?tripId=${bookingId}`;
  }

  history.push({ pathname: TRIPS_HOME, search: myTripsSearchParams });
};

export const skipShopAction = (selection?: ExchangeAction) =>
  !selection ||
  selection.ExchangeAction === ExchangeActionEnum.keep ||
  selection.ExchangeAction === ExchangeActionEnum.remove;
