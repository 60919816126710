import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AirlineCode,
  AirportCode,
  FareClasses,
  Flights,
  FlightShopStep,
  FlightSortOption,
  IFlightGridFareMap,
  IFlightNumberFilter,
  IReturnFlightsMap,
  IShoppedTrip,
  IExchangeType,
  ITimeRange,
  ITripDetails,
  ITripSummariesById,
  Maybe,
  SliceStopCountFilter,
  TripDetails,
  ExchangeForTypeEnum,
  ExchangeActionEnum,
} from "redmond";

import { DAY_IN_MINUTES } from "../constants";
import { setExchangeType, setFlights, resetState } from "./search";
import { skipShopAction } from "../utils/helpers";

export interface FlightReshopState {
  filters: {
    airlines: AirlineCode[];
    airports: AirportCode[];
    fareClasses: FareClasses;
    flightNumbers: IFlightNumberFilter[];
    maxPrice: number;
    outboundArrivalTime: ITimeRange;
    outboundDepartureTime: ITimeRange;
    returnArrivalTime: ITimeRange;
    returnDepartureTime: ITimeRange;
    stopsCount: SliceStopCountFilter;
  };
  exchangeType: IExchangeType;
  flights: Maybe<Flights>;
  flightGridFares: IFlightGridFareMap;
  hasTripDetailsError: boolean;
  hasTripSummariesError: boolean;
  isTripDetailsLoading: boolean;
  isTripSummariesLoading: boolean;
  outboundFareClass: number;
  returnFlights: IReturnFlightsMap;
  shoppedTrip: IShoppedTrip;
  shopStep: FlightShopStep;
  sortOption: FlightSortOption;
  tripIds: string[];
  tripDetails: ITripDetails;
  tripSummaries: ITripSummariesById;
}

const getInitialTimeRange = () => ({
  max: DAY_IN_MINUTES,
  min: 0,
});

export type IFetchTripSummariesPayload = {
  isMobile?: boolean;
};

export type IFetchTripDetailsPayload = {
  tripId: string;
  isHackerFareReturn?: boolean;
};

const initialState: FlightReshopState = {
  filters: {
    airlines: [],
    airports: [],
    fareClasses: {
      basic: false,
      standard: false,
      enhanced: false,
      premium: false,
      luxury: false,
    },
    flightNumbers: [],
    maxPrice: Number.MAX_SAFE_INTEGER,
    outboundArrivalTime: getInitialTimeRange(),
    outboundDepartureTime: getInitialTimeRange(),
    returnArrivalTime: getInitialTimeRange(),
    returnDepartureTime: getInitialTimeRange(),
    stopsCount: SliceStopCountFilter.ANY_NUMBER,
  },
  exchangeType: {
    AirExchangeForType: ExchangeForTypeEnum.roundTrip,
    outboundSelection: { ExchangeAction: ExchangeActionEnum.change },
    returnSelection: { ExchangeAction: ExchangeActionEnum.change },
  },
  flightGridFares: {},
  flights: null,
  hasTripDetailsError: false,
  hasTripSummariesError: false,
  isTripDetailsLoading: false,
  isTripSummariesLoading: false,
  outboundFareClass: 0,
  returnFlights: {},
  shopStep: FlightShopStep.ChooseDeparture,
  shoppedTrip: {
    outgoingFareId: "",
    outgoingSliceId: "",
    tripId: "",
  },
  sortOption: FlightSortOption.FareScore,
  tripIds: [],
  tripDetails: {},
  tripSummaries: {},
};

export const flightReshopSlice = createSlice({
  initialState,
  name: "exchange/flightReshop",
  reducers: {
    fetchTripDetails: (
      state: FlightReshopState,
      _payload: PayloadAction<IFetchTripDetailsPayload>
    ) => {
      // payload used in fetchTripDetailsSaga
      state.isTripDetailsLoading = true;
    },
    fetchTripSummaries: (
      state: FlightReshopState,
      _payload: PayloadAction<IFetchTripSummariesPayload>
    ) => {
      // payload used in fetchTripSummariesSaga
      state.isTripSummariesLoading = true;
    },
    resetAllFilters: (
      state: FlightReshopState,
      _payload: PayloadAction<void>
    ) => {
      state.filters = initialState.filters;
    },
    resetFilter: (state: FlightReshopState, action: PayloadAction<string>) => {
      const { payload: filter } = action;

      state.filters[filter] = initialState.filters[filter];
    },
    setAirlinesFilter: (
      state: FlightReshopState,
      action: PayloadAction<AirlineCode[]>
    ) => {
      state.filters.airlines = action.payload;
    },
    setAirportsFilter: (
      state: FlightReshopState,
      action: PayloadAction<AirportCode[]>
    ) => {
      state.filters.airports = action.payload;
    },
    setFareClassesFilter: (
      state: FlightReshopState,
      action: PayloadAction<FareClasses>
    ) => {
      state.filters.fareClasses = action.payload;
    },
    setFlightGridFares: (
      state: FlightReshopState,
      action: PayloadAction<IFlightGridFareMap>
    ) => {
      state.flightGridFares = action.payload;
    },
    setFlightNumberFilters: (
      state: FlightReshopState,
      action: PayloadAction<IFlightNumberFilter[]>
    ) => {
      state.filters.flightNumbers = action.payload;
    },
    setHasTripDetailsError: (
      state: FlightReshopState,
      action: PayloadAction<boolean>
    ) => {
      state.hasTripDetailsError = action.payload;
    },
    setHasTripSummariesError: (
      state: FlightReshopState,
      action: PayloadAction<boolean>
    ) => {
      state.hasTripSummariesError = action.payload;
      state.isTripSummariesLoading = false;
    },
    setIsTripDetailsLoading: (
      state: FlightReshopState,
      action: PayloadAction<boolean>
    ) => {
      state.isTripDetailsLoading = action.payload;
    },
    setIsTripSummariesLoading: (
      state: FlightReshopState,
      action: PayloadAction<boolean>
    ) => {
      state.isTripSummariesLoading = action.payload;
    },
    setMaxPriceFilter: (
      state: FlightReshopState,
      action: PayloadAction<number>
    ) => {
      state.filters.maxPrice = action.payload;
    },
    setOutboundArrivalTimeFilter: (
      state: FlightReshopState,
      action: PayloadAction<Partial<ITimeRange>>
    ) => {
      state.filters.outboundArrivalTime = {
        ...state.filters.outboundArrivalTime,
        ...action.payload,
      };
    },
    setOutboundDepartureTimeFilter: (
      state: FlightReshopState,
      action: PayloadAction<Partial<ITimeRange>>
    ) => {
      state.filters.outboundDepartureTime = {
        ...state.filters.outboundDepartureTime,
        ...action.payload,
      };
    },
    setOutboundFareClass: (
      state: FlightReshopState,
      action: PayloadAction<number>
    ) => {
      state.outboundFareClass = action.payload;
    },
    setReturnArrivalTimeFilter: (
      state: FlightReshopState,
      action: PayloadAction<Partial<ITimeRange>>
    ) => {
      state.filters.returnArrivalTime = {
        ...state.filters.returnArrivalTime,
        ...action.payload,
      };
    },
    setReturnDepartureTimeFilter: (
      state: FlightReshopState,
      action: PayloadAction<Partial<ITimeRange>>
    ) => {
      state.filters.returnDepartureTime = {
        ...state.filters.returnDepartureTime,
        ...action.payload,
      };
    },
    setReturnFlights: (
      state: FlightReshopState,
      action: PayloadAction<IReturnFlightsMap>
    ) => {
      state.returnFlights = action.payload;
    },
    setSelectedOutgoingSlice: (
      state: FlightReshopState,
      action: PayloadAction<Partial<IShoppedTrip>>
    ) => {
      const {
        outgoingFareId = "",
        outgoingFareRating = undefined,
        outgoingSliceId = "",
        returnFareId,
        returnFareRating,
        returnSliceId,
        tripId = "",
      } = action.payload;

      state.shoppedTrip.outgoingFareId = outgoingFareId;
      state.shoppedTrip.outgoingFareRating = outgoingFareRating;
      state.shoppedTrip.outgoingSliceId = outgoingSliceId;
      state.shoppedTrip.tripId = tripId;

      // these values only provided if not changing return so check & set
      if (returnFareId) state.shoppedTrip.returnFareId = returnFareId;
      if (returnFareRating)
        state.shoppedTrip.returnFareRating = returnFareRating;
      if (returnSliceId) state.shoppedTrip.returnSliceId = returnSliceId;
    },
    setSelectedReturnSlice: (
      state: FlightReshopState,
      action: PayloadAction<Partial<IShoppedTrip>>
    ) => {
      const {
        returnFareId = undefined,
        returnFareRating = undefined,
        returnSliceId = undefined,
        tripId = "",
      } = action.payload;

      state.shoppedTrip.returnFareId = returnFareId;
      state.shoppedTrip.returnFareRating = returnFareRating;
      state.shoppedTrip.returnSliceId = returnSliceId;
      state.shoppedTrip.tripId = tripId;
    },
    setShopStep: (
      state: FlightReshopState,
      action: PayloadAction<FlightShopStep>
    ) => {
      state.shopStep = action.payload;
    },
    setShoppedTrip: (
      state: FlightReshopState,
      action: PayloadAction<IShoppedTrip>
    ) => {
      state.shoppedTrip = action.payload;
    },
    setSortOption: (
      state: FlightReshopState,
      action: PayloadAction<FlightSortOption>
    ) => {
      state.sortOption = action.payload;
    },
    setStopCountFilter: (
      state: FlightReshopState,
      action: PayloadAction<SliceStopCountFilter>
    ) => {
      state.filters.stopsCount = action.payload;
    },
    setTripDetails: (
      state: FlightReshopState,
      action: PayloadAction<TripDetails>
    ) => {
      const { payload: tripDetails } = action;

      state.tripDetails[tripDetails.id] = tripDetails;
    },
    setTripIds: (state: FlightReshopState, action: PayloadAction<string[]>) => {
      state.tripIds = action.payload;
    },
    setTripSummaries: (
      state: FlightReshopState,
      action: PayloadAction<ITripSummariesById>
    ) => {
      state.tripSummaries = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetState, () => initialState)
      .addCase(
        setExchangeType,
        (state: FlightReshopState, action: PayloadAction<IExchangeType>) => {
          const { outboundSelection, returnSelection } = action.payload;
          const skipChooseDeparture = skipShopAction(outboundSelection);
          const skipChooseReturn = skipShopAction(returnSelection);

          state.exchangeType = action.payload;

          if (skipChooseDeparture) {
            state.shopStep = FlightShopStep.ChooseReturn;
          } else if (skipChooseReturn) {
            state.shopStep = FlightShopStep.ChooseDeparture;
          }
        }
      )
      .addCase(
        setFlights,
        (state: FlightReshopState, action: PayloadAction<Flights>) => {
          state.flights = action.payload;
          state.isTripSummariesLoading = false;
        }
      )
      .addDefaultCase((_state, _action) => {});
  },
});

export const {
  fetchTripDetails,
  fetchTripSummaries,
  resetAllFilters,
  resetFilter,
  setAirlinesFilter,
  setAirportsFilter,
  setFareClassesFilter,
  setFlightGridFares,
  setFlightNumberFilters,
  setHasTripDetailsError,
  setHasTripSummariesError,
  setIsTripDetailsLoading,
  setIsTripSummariesLoading,
  setMaxPriceFilter,
  setOutboundArrivalTimeFilter,
  setOutboundDepartureTimeFilter,
  setOutboundFareClass,
  setReturnArrivalTimeFilter,
  setReturnDepartureTimeFilter,
  setReturnFlights,
  setSelectedOutgoingSlice,
  setSelectedReturnSlice,
  setShopStep,
  setShoppedTrip,
  setSortOption,
  setStopCountFilter,
  setTripDetails,
  setTripIds,
  setTripSummaries,
} = flightReshopSlice.actions;

export default flightReshopSlice.reducer;
